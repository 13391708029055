<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'agreements' }">{{$t('label_agreements')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_addconvert_to_agreement')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-col cols="12" md="12">
            <b-card
                    no-body
                    class="mb-0"
            >

                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">
                        {{ action == 'editing'? $t('label_addconvert_to_agreement') :
                        $t('label_addconvert_to_agreement') }}
                    </h5>

                </div>


                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="MODULE_PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >


                    <b-row class="flex-wrap agrm-add-client-toolbar align-items-end ">

                        <b-col cols="12" md="3">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_chose_related_client')"
                                    rules=""
                                    slim
                            >
                                <b-form-group
                                        class="full-width-sm"
                                        :label="$t('label_chose_related_client')"

                                        :state="getValidationState(validationProps)"
                                >

                                    <infinity-scroll
                                            v-model="itemData.contact_id"
                                            :multiple="false"
                                            class="mr-1 "
                                            style="min-width: 220px"
                                            selected-prop="id"
                                            url="/contact"
                                            @input="contactChanged($event)"
                                            :placeholder="$t('label_select')"
                                            :default-selection="chosenContact"

                                    >
                                        <template #label="{item}">{{ item.first_name? (item.first_name + ' ' +
                                            item.last_name) : '' }}
                                        </template>

                                    </infinity-scroll>
                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" md="9">
                            <b-button type="button" class="mr-1 mb-1" variant="primary"
                                      @click.prevent="isContactSidebarOpen = true">{{$t('label_add_client')}}
                            </b-button>
                            <b-button class="mb-1" v-if="allowAssignContactData" variant="success"
                                      @click="assignContactData()">
                                {{$t('label_copy_contact_data_to_agreement')}}
                            </b-button>
                        </b-col>

                    </b-row>

                    <b-row>
                        <b-col cols="12" md="3">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_agreement_date')"
                                    rules="required"
                            >
                                <b-form-group
                                        :label="$t('label_agreement_date')"
                                        class="required"
                                        :state="getValidationState(validationProps)"
                                >

                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="itemData.agreement_at"

                                            @input="itemData.agreement_at = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" md="3">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_limitation_date')"
                                    rules=""
                            >
                                <b-form-group
                                        :label="$t('label_limitation_date')"
                                        label-for="limitation_date"
                                        :state="getValidationState(validationProps)"
                                >

                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="itemData.limitation_at"

                                            @input="itemData.limitation_at = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" md="3">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_contract_termination_date')"
                                    rules=""
                            >
                                <b-form-group
                                        :label="$t('label_contract_termination_date')"
                                        class=""
                                        :state="getValidationState(validationProps)"
                                >

                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="itemData.termination_at"

                                            @input="itemData.termination_at = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" md="3">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_agreement_expiration_date')"
                                    rules="required"
                            >
                                <b-form-group
                                        class="required"
                                        :label="$t('label_agreement_expiration_date')"
                                        :state="getValidationState(validationProps)"
                                >

                                    <custom-date-picker
                                            :class="{'date--picker': true , 'is-invalid':getValidationState(validationProps) === false, 'is-valid':getValidationState(validationProps) === true}"
                                            :locale="$store.state.appConfig.locale"
                                            :placeholder="$t('label_DD-MM-YYYY')"
                                            :value="itemData.deadline_at"

                                            @input="itemData.deadline_at = $event"
                                    >
                                        <template #clear-btn="{ vm }">
                                            <feather-icon icon="XIcon" size="14"/>
                                        </template>
                                        <div slot="label">
                                            <feather-icon title="Clear" data-toggle icon="CalendarIcon" size="18"/>
                                        </div>
                                    </custom-date-picker>

                                    <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>

                    <b-form-checkbox
                            switch
                            v-model="itemData.notify_about_deadline"
                            value="1"
                            class="mt-1 mb-1"
                            unchecked-value="0"
                    >
                        <label >{{ $t('label_notify_about_deadline') }}</label>
                    </b-form-checkbox>

                    <template v-if="itemData.notify_about_deadline == 1">
                        <validation-provider
                                #default="validationProps"
                                :name="$t('label_notify_users')"
                                rules="required"
                        >
                            <b-form-group
                                    :label="$t('label_notify_users')"
                                    class=""
                                    :state="getValidationState(validationProps)"
                            >

                                <infinity-scroll
                                        v-model="itemData.notify_users_ids"
                                        selected-prop="id"
                                        url="/user"
                                        :multiple="true"
                                        :placeholder="$t('label_select')"
                                        :default-selection="chosenNotifyUsers"

                                >
                                    <template #label="{item}">{{item.name}}</template>

                                </infinity-scroll>

                                <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <b-form-group
                                v-if="itemData.email"
                                :label="$t('label_notify_contact')"
                                class=""
                        >
                            <b-form-checkbox
                                    v-model="itemData.notify_contact"
                                    value="1"
                                    class=""
                                    unchecked-value="0"
                            >
                                <label >{{ $t('label_notify_contact') }} <span >{{"(" + itemData.email + ")" }}</span></label>
                            </b-form-checkbox>
                        </b-form-group>
                        <validation-provider
                                #default="validationProps"
                                :name="$t('label_notify_before_days')"
                                rules="required|numeric|min:0"
                        >
                            <b-form-group
                                    :label="$t('label_notify_before_days')"
                                    label-for="notify_before_days"
                            >
                                <b-form-input
                                        type="number"
                                        id="notify_before_days"
                                        v-model="itemData.notify_before_days"
                                        :state="getValidationState(validationProps)"
                                        trim
                                />

                                <b-form-invalid-feedback>
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <br>
                        <br>
                    </template>


                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_deal_number')"
                            rules="required"
                    >
                        <b-form-group
                                class="required"
                                :label="$t('label_deal_number')"

                        >
                            <b-form-input

                                    v-model="itemData.deal_number"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_deal_number')"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_choose_the_type_of_customer')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_choose_the_type_of_customer')"
                                class="required"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select
                                    v-model="itemData.customer_type_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="customer_types"
                                    :reduce="val => val.id"
                                    label="name"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"
                                    input-id="customer_type_id"
                                    @input="showOrHidePesselNip($event)"
                            >
                                <template v-slot:option="option">
                                    <span class="">{{ option.name }}</span>
                                </template>
                                <template v-slot:selected-option="option">
                                    <!--<span :class="option.icon"></span>-->
                                    <span class="">{{ option.name }}</span>
                                </template>

                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_first_name')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_first_name')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.first_name"
                                    :state="getValidationState(validationProps)"
                                    :placeholder="$t('label_first_name')"
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Username -->
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_last_name')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_last_name')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.last_name"
                                    :placeholder="$t('label_last_name')"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_address_from_agreement')"
                            rules=""
                            slim
                    >
                        <b-form-group
                                :label="$t('label_address_from_agreement')"
                                label-for="n-address_agreement"
                        >
                            <b-form-textarea
                                    id="n-address_agreement"
                                    :state="getValidationState(validationProps)"
                                    v-model="itemData.address_agreement"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider

                            #default="validationProps"
                            :name="$t('label_correspondence_address')"
                            rules=""
                            slim
                    >
                        <b-form-group
                                :label="$t('label_correspondence_address')"
                                label-for="n-correspondence_address"
                        >
                            <b-form-textarea
                                    id="n-address_correspondence"
                                    :state="getValidationState(validationProps)"
                                    v-model="itemData.address_correspondence"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <validation-provider v-if="showPessel"
                                         #default="validationProps"
                                         :name="$t('label_pesel_number')"
                                         rules=""
                    >
                        <b-form-group
                                :label="$t('label_pesel_number')"
                                label-for="pesel"
                        >
                            <b-form-input
                                    id="pesel"
                                    v-model="itemData.pesel"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider v-if="showNip"
                                         #default="validationProps"
                                         :name="$t('label_nip')"
                                         rules=""
                    >
                        <b-form-group
                                :label="$t('label_nip')"
                                label-for="nip"
                        >
                            <b-form-input
                                    id="nip"
                                    v-model="itemData.nip"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Email -->
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_email_address')"
                            rules="required|email"
                    >
                        <b-form-group
                                :label="$t('label_email_address')"
                                class="required"
                        >
                            <b-form-input
                                    v-model="itemData.email"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_phone')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_phone')"
                                label-for="phone"
                        >
                            <b-form-input
                                    id="phone"
                                    v-model="itemData.phone"
                                    :state="getValidationState(validationProps)"
                                    trim
                            />

                            <b-form-invalid-feedback>
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <!--*************************PROCURATORS BLOCK*********************************************************-->
                    <dynamic-items-block
                            class="mt-2"
                            alias="procurators"
                            label="label_procurator"
                            @deleteItemServer="deleteProcurator($event)"
                            :show-items-section.sync="showProcuratorsSection"
                            :blank-item="{name: '', func: '', phone: '', email: ''}"
                            :items.sync="savedProcurators"
                            :new-items.sync="new_procurators"
                    >
                        <template #form="{item, ind, deleteItem}">
                            <button @click="deleteItem()"
                                    :style="{top: ind>0? '14px' : '-17px'}"
                                    class="remove-procurator-item btn  "
                                    type="button">
                                <feather-icon color="red" title="Clear" data-toggle icon="XIcon" size="25"/>
                            </button>
                            <hr v-if="ind > 0" style="width:100%">
                            <b-col cols="12" sm="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :key="ind"
                                        :name="$t('label_name_and_surname')"
                                        slim
                                        rules="required"
                                        :vid="'proc_name_and_surname_' + (item.id? '__' + item.id :  ind)"
                                >
                                    <b-form-group
                                            :label="$t('label_name_and_surname')"
                                            class="required"
                                    >
                                        <b-form-input

                                                v-model="item.name"
                                                :state="getValidationState(validationProps)"
                                                trim
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" sm="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_function')"
                                        slim
                                        rules="required"
                                        :vid="'proc_function_' + (item.id? '__' + item.id :  ind)"
                                >
                                    <b-form-group
                                            :label="$t('label_function')"
                                            class="required"
                                    >

                                        <b-form-input

                                                v-model="item.func"
                                                :state="getValidationState(validationProps)"
                                                trim
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>

                            <b-col cols="12" sm="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_email')"
                                        slim
                                        rules="required|email"
                                        :vid="'proc_email_' + (item.id? '__' + item.id :  ind)"
                                >
                                    <b-form-group
                                            :label="$t('label_email')"
                                            class="required"
                                    >
                                        <b-form-input

                                                v-model="item.email"
                                                :state="getValidationState(validationProps)"
                                                trim
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                            <b-col cols="12" sm="12" md="6">

                                <validation-provider
                                        #default="validationProps"
                                        :name="$t('label_phone')"
                                        slim
                                        rules="required"
                                        :vid="'proc_phone_' + (item.id? '__' + item.id :  ind)"
                                >
                                    <b-form-group
                                            :label="$t('label_phone')"
                                            class="required"
                                    >

                                        <b-form-input

                                                v-model="item.phone"
                                                :state="getValidationState(validationProps)"
                                                trim
                                        />
                                        <b-form-invalid-feedback>
                                            {{ validationProps.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </b-col>
                        </template>

                    </dynamic-items-block>
                    <!--*************************PROCURATORS BLOCK*********************************************************-->

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_type_of_ageement')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_type_of_ageement')"
                                class="required"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select

                                    v-model="itemData.agreement_type_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="agreement_types"
                                    :reduce="val => val.id"
                                    label="name"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"

                            >
                                <template v-slot:option="option">
                                    <span class="">{{ option.name }}</span>
                                </template>
                                <template v-slot:selected-option="option">
                                    <!--<span :class="option.icon"></span>-->
                                    <span class="">{{ option.name }}</span>
                                </template>

                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_subject_of_the_order')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_subject_of_the_order')"
                                class="required"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select

                                    v-model="itemData.order_subject_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="order_subjects"
                                    :reduce="val => val.id"
                                    label="name"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"

                            >
                                <template v-slot:option="option">
                                    <span class="">{{ option.name }}</span>
                                </template>
                                <template v-slot:selected-option="option">
                                    <!--<span :class="option.icon"></span>-->
                                    <span class="">{{ option.name }}</span>
                                </template>

                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            v-if="agreementType == 'provision_legal_services' || agreementType == 'legal_service_order_card'"
                            #default="validationProps"
                            :name="$t('label_agreement_description')"
                            rules=""
                            slim
                    >
                        <b-form-group
                                :label="$t('label_agreement_description')"
                                label-for="agreement_desc"
                        >
                            <b-form-textarea
                                    id="agreement_desc"
                                    :state="getValidationState(validationProps)"
                                    v-model="itemData.agreement_desc"
                            ></b-form-textarea>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                    <!--**********************************************************************************-->
                    <payment-block v-if="action != 'editing'" :is-annex="false"
                                   :item-payment="item_payment"
                                   @paymentDataUpdated="itemData.payment = $event; "
                                   :agreement-type="agreementType"
                    ></payment-block>
                    <!--**************************************************************************************-->
                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_deal_type')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_deal_type')"
                                class="required"
                                :state="getValidationState(validationProps)"
                        >
                            <v-select
                                    v-model="itemData.deal_type_id"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="deal_types"
                                    :reduce="val => val.id"
                                    label="name"
                                    :clearable="false"
                                    :placeholder="$t('label_select')"

                            >
                                <template v-slot:option="option">
                                    <span class="">{{ option.name }}</span>
                                </template>
                                <template v-slot:selected-option="option">
                                    <!--<span :class="option.icon"></span>-->
                                    <span class="">{{ option.name }}</span>
                                </template>

                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_consultant_leading_case')"
                            rules="required"
                    >
                        <b-form-group
                                :label="$t('label_consultant_leading_case')"
                                class="required"
                                :state="getValidationState(validationProps)"
                        >

                            <infinity-scroll
                                    v-model="itemData.consultant_id"
                                    selected-prop="id"
                                    url="/consultant"
                                    :multiple="false"
                                    :placeholder="$t('label_select')"
                                    :default-selection="chosenConsultant"
                                    @option:selected="(val) => mergeChosenNotifyUsers(val)"
                                    @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                            >

                                <template #label="{item}">{{item.name}}</template>

                            </infinity-scroll>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_accountants')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_accountants')"
                                class=""
                                :state="getValidationState(validationProps)"
                        >

                            <infinity-scroll
                                    v-model="itemData.accountant_ids"
                                    selected-prop="id"
                                    url="/accountant"
                                    :multiple="true"
                                    :placeholder="$t('label_select')"
                                    :default-selection="chosenAccountants"
                                    @option:selected="(val) => mergeChosenNotifyUsers(val)"
                                    @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                            >
                                <template #label="{item}">{{item.name}}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_recommended')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_recommended')"
                                class=""
                                :state="getValidationState(validationProps)"
                        >

                            <infinity-scroll
                                    v-model="itemData.agent_id"
                                    selected-prop="id"
                                    url="/agent"
                                    :multiple="false"
                                    :placeholder="$t('label_select')"
                                    :default-selection="chosenAgent"
                                    @option:selected="(val) => mergeChosenNotifyUsers(val)"
                                    @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                            >
                                <template #label="{item}">{{item.name}}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_solicitors')"
                            :rules="is_deal? 'required' : ''"
                    >
                        <b-form-group
                                :label="$t('label_solicitors')"
                                :class="is_deal? 'required' : ''"
                                :state="getValidationState(validationProps)"
                        >

                            <infinity-scroll
                                    v-model="itemData.solicitor_ids"
                                    selected-prop="id"
                                    url="/solicitor"
                                    :multiple="true"
                                    :placeholder="$t('label_select')"
                                    :default-selection="chosenSolicitors"
                                    @option:selected="(val) => mergeChosenNotifyUsers(val)"
                                    @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                            >

                                <template #label="{item}">{{item.name}}</template>

                            </infinity-scroll>
                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <validation-provider
                            #default="validationProps"
                            :name="$t('label_secretaries')"
                            rules=""
                    >
                        <b-form-group
                                :label="$t('label_secretaries')"
                                class=""
                                :state="getValidationState(validationProps)"
                        >

                            <infinity-scroll
                                    v-model="itemData.secretary_ids"
                                    selected-prop="id"
                                    url="/secretary"
                                    :multiple="true"
                                    :placeholder="$t('label_select')"
                                    :default-selection="chosenSecretaries"
                                    @option:selected="(val) => mergeChosenNotifyUsers(val)"
                                    @option:deselected="(val) => unmergeChosenNotifyUsers(val)"
                            >
                                <template #label="{item}">{{item.name}}</template>

                            </infinity-scroll>

                            <b-form-invalid-feedback :state="getValidationState(validationProps)">
                                {{ validationProps.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>


                    <!-- Form Actions -->
                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_submit') : $t('label_submit') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                @click="$router.go(-1)"
                        >

                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
        <add-contact
                @item-added="chosenContact = $event; assignContactData()"
                :edited-item="false"
                :is-sidebar-open.sync="isContactSidebarOpen"
        ></add-contact>
        <!--@refetch-data="refreshDataTable"-->

    </div>

</template>


<script>
    import {
        BSidebar, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox
    } from 'bootstrap-vue'

    import {ValidationProvider, ValidationObserver} from 'vee-validate'

    import vSelect from 'vue-select'
    import infinityScroll from '@/views/components/infinityScroll'
    import addContact from './../contact/modals/itemProcessingModal'
    import paymentBlock from './includes/paymentBlock'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'

    import {AGREEMENT_PREFIX, DEAL_PREFIX} from './moduleHelper'
    import {CONTACT_PREFIX} from './../contact/moduleHelper'

    export default {
        components: {

            BSidebar,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            addContact,
            BFormCheckbox,
            BFormTextarea,
            // Form Validation
            ValidationProvider,
            ValidationObserver,

            paymentBlock,
            dynamicItemsBlock,
            infinityScroll
        },

        data() {
            return {

                CONTACT_PREFIX,
                AGREEMENT_PREFIX,
                DEAL_PREFIX,
                MODULE_PREFIX: '',

                is_deal: false,

                create_from_contact_id: false,
                edited_agreement_id: false,
                edited_deal_id: false,
                showProcuratorsSection: false,
                isContactSidebarOpen: false,

                action: 'adding',

                allowAssignContactData: false,
                showPessel: false,
                showNip: false,

                itemData: {},

                blankItemData: {

                    contact_id: null,
                    customer_type_id: null,
                    first_name: '',
                    last_name: '',
                    phone: '',
                    email: '',
                    consultant_id: null,
                    deal_type_id: null,
                    agent_id: null,
                    accountant_ids: [],
                    secretary_ids: [],
                    agreement_at: '',
                    limitation_at: '',
                    deadline_at:'',
                    deal_number: '',
                    address_agreement: '',
                    address_correspondence: '',
                    pesel: '',
                    nip: '',
                    solicitor_ids: [],
                    agreement_type_id: '',
                    order_subject_id: '',
                    agreement_desc: '',
                    termination_at: '',
                    payment: {},
                    notify_about_deadline: 0,
                    notify_before_days:0,
                    notify_users_ids:[],
                    notify_contact: 0
                },

                savedProcurators: [],
                new_procurators: [],

                item_payment: false,

                deal_types: [],
                customer_types: [],
                agreement_types: [],
                order_subjects: [],

                prevRoute: '',

                chosenContact: false,
                chosenAgent: false,
                chosenAccountants: false,
                chosenSecretaries: false,
                chosenConsultant: false,
                chosenSolicitors: [],
                chosenNotifyUsers:[]
            }
        },

        created() {

            this.is_deal = this.$router.currentRoute.path.includes('/' + this.DEAL_PREFIX + '/');
            this.create_from_contact_id = this.$router.currentRoute.params.contact_id;
            this.edited_agreement_id = this.$router.currentRoute.params.id;
            this.edited_deal_id = this.$router.currentRoute.params.deal_id;

            this.MODULE_PREFIX = this.is_deal ? this.DEAL_PREFIX : this.AGREEMENT_PREFIX;

            if (this.create_from_contact_id) {
                this.getAndSetChosenContact(this.create_from_contact_id, true);
            }
            this.initData();

            this.getPageData();

        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.prevRoute = from;

            })
        },

        computed: {
            agreementType() {

                if (this.itemData && this.agreement_types && this.agreement_types.length > 0 && this.itemData.agreement_type_id) {
                    return this.agreement_types.findWhere('id', this.itemData.agreement_type_id).name_alias;
                }
            }
        },
        methods: {
            initData() {

                if (this.edited_agreement_id) {

                    this.action = 'editing';

                    this.async('get', '/' + this.AGREEMENT_PREFIX + '/' + this.edited_agreement_id, {}, function (resp) {

                        let editedItem = Object.assign({}, this.blankItemData);
                        let actualAgreement = resp.data;
                        for (let prop in editedItem) {
                            if (actualAgreement.hasOwnProperty(prop)) {
                                editedItem[prop] = actualAgreement[prop];
                            }
                        }

                        editedItem.id = actualAgreement.id;
                        editedItem.order_subject_id = actualAgreement.order_subject_id > 0 ? actualAgreement.order_subject_id : '';

                        if (actualAgreement.contact_id)
                            this.getAndSetChosenContact(actualAgreement.contact_id);

                        if (actualAgreement.procurators && actualAgreement.procurators.length) {

                            let preparedProcurators = [];
                            for (let i = 0; i < actualAgreement.procurators.length; i++) {
                                let item = actualAgreement.procurators[i];
                                preparedProcurators.push({
                                    name: item.initials,
                                    func: item.function,
                                    email: item.email,
                                    phone: item.phone
                                });
                            }
                            this.savedProcurators = preparedProcurators;

                            this.showProcuratorsSection = true;
                        }
                        this.item_payment = actualAgreement.payments;

                        editedItem.payment = {};
                        editedItem.agreement_at = formatDate(editedItem.agreement_at, 'DD/MM/YYYY', 'unix');
                        editedItem.termination_at = editedItem.termination_at ? formatDate(editedItem.termination_at, 'DD/MM/YYYY', 'unix') : '';
                        editedItem.limitation_at = editedItem.limitation_at ? formatDate(editedItem.limitation_at, 'DD/MM/YYYY', 'unix') : '';
                        editedItem.deadline_at = editedItem.deadline_at ? formatDate(editedItem.deadline_at, 'DD/MM/YYYY', 'YYYY-MM-DD') : '';
                        editedItem.solicitor_ids = actualAgreement.solicitors ? actualAgreement.solicitors.pluck('id') : [];
                        editedItem.accountant_ids = actualAgreement.accountants ? actualAgreement.accountants.pluck('id') : [];
                        editedItem.secretary_ids = actualAgreement.secretaries ? actualAgreement.secretaries.pluck('id') : [];
                        this.itemData = editedItem;

                        if (editedItem.agent_id) {
                            this.async('get', '/agent/' + editedItem.agent_id, {}, function (resp) {
                                this.chosenAgent = resp.data;
                            });
                        }

                        this.async('get', '/consultant/' + editedItem.consultant_id, {}, function (resp) {
                            this.chosenConsultant = resp.data;
                        });
                        this.chosenSolicitors = actualAgreement.solicitors;
                        this.chosenNotifyUsers = actualAgreement.notify_users;
                        this.chosenAccountants = actualAgreement.accountants;
                        this.chosenSecretaries = actualAgreement.secretaries;
                        // console.log(this.chosenSolicitors,this.chosenAccountants,this.chosenSecretaries);


                    });

                } else {
                    this.action = 'adding';
                    this.itemData = Object.assign({}, this.blankItemData);

                }
            },
            getAndSetChosenContact(id, allowAssign) {
                this.async('get', '/contact/' + id, {}, (res) => {
                    this.chosenContact = res.data;
                    if (allowAssign) {
                        // this.allowAssignContactData = true;
                        this.assignContactData();
                    }

                });
            },
            assignContactData(clear) {

                let chosenContact = clear ? false : this.chosenContact;

                this.itemData.first_name = clear ? '' : chosenContact.first_name;
                this.itemData.contact_id = clear ? '' : chosenContact.id;
                this.itemData.last_name = clear ? '' : chosenContact.last_name;
                this.itemData.phone = clear ? '' : chosenContact.phone;
                this.itemData.email = clear ? '' : chosenContact.email;
                this.itemData.consultant_id = clear ? '' : chosenContact.consultant_id;
                this.itemData.deal_type_id = clear ? '' : chosenContact.deal_type_id;
                this.itemData.agent_id = clear ? '' : chosenContact.agent_id;

                if (chosenContact.agent) {
                    this.chosenAgent = chosenContact.agent;
                }
                if (chosenContact.consultant) {
                    this.chosenConsultant = chosenContact.consultant;
                }
                if (chosenContact.solicitors) {
                    this.chosenSolicitors = chosenContact.solicitors;
                }
                if (chosenContact.notify_users) {
                    this.chosenNotifyUsers = chosenContact.notify_users;
                }
            },
            onSubmit() {

                for (let payType in this.itemData.payment) {
                    if (typeof this.itemData.payment[payType] == 'object')
                        for (let payProp in  this.itemData.payment[payType]) {
                            if (payProp == 'net_payment' || payProp == 'gross_payment') {
                                this.itemData.payment[payType][payProp] = this.itemData.payment[payType][payProp].formatPriceToNumber();
                            }
                        }
                }

                let procurators = this.new_procurators;

                if (procurators && procurators.length > 0) {
                    let preparedProcurators = {};
                    this.itemData.procurator = 1;
                    for (let i = 0; i < procurators.length; i++) {
                        let proc = procurators[i];
                        for (let prop in proc) {
                            if (!preparedProcurators.hasOwnProperty(prop)) {
                                preparedProcurators[prop] = [];
                            }
                            preparedProcurators[prop].push(proc[prop]);
                        }
                    }

                    this.itemData.procurators = preparedProcurators;

                }

                if (this.action == 'adding') {

                    this.async('post', '/' + this.MODULE_PREFIX, this.itemData, function (resp) {
                        this.$router.push({name: (this.MODULE_PREFIX) + '-details', params: {id: resp.data.item.id}});
                    });

                } else {
                    this.async('put', '/' + this.MODULE_PREFIX + '/' + (this.is_deal ? this.edited_deal_id : this.itemData.id), this.itemData, function (resp) {

                        this.$router.push({
                            name: this.MODULE_PREFIX + '-details',
                            params: {id: this.is_deal ? this.edited_deal_id : this.edited_agreement_id}
                        });
                    });

                }
            },

            showOrHidePesselNip(id) {
                let item = this.customer_types.findWhere('id', id);
                if (item && item.name_alias != 'firma') {
                    this.showPessel = true;
                    this.showNip = false;
                } else {
                    this.showPessel = false;
                    this.showNip = true;
                }
            },

            getPageData() {

                this.async('get', '/select_options', {params: {options: ['order_subject', 'agreement_types', 'customer_types', 'deal_subjects']}}, function (resp) {
                    this.order_subjects = resp.data.order_subject;
                    this.deal_types = resp.data.deal_subjects; //todo change on server updating
                    this.customer_types = resp.data.customer_types;
                    this.agreement_types = resp.data.agreement_types;

                });

            },
            deleteProcurator(item) {
                this.savedProcurators = this.savedProcurators.filter((o) => {
                    return o.id != item.id
                });
            },
            contactChanged(contact_id) {
                if (contact_id) {

                    this.allowAssignContactData = true;
                    this.itemData.contact_id = contact_id;
                    this.getAndSetChosenContact(contact_id);
                } else {
                    this.allowAssignContactData = false;
                    this.assignContactData(true);
                }
            },

            mergeChosenNotifyUsers(val){
                // if(val) {
                //   if(val.id != undefined) {
                //       if(!this.itemData.notify_users_ids.includes(val.id)) {
                //           this.itemData.notify_users_ids.push(val.id);
                //           this.chosenNotifyUsers.push(val);
                //       }
                //   } else {
                //         for(let i=0; i<val.length; i++) {
                //             if(!this.itemData.notify_users_ids.includes(val[i].id)) {
                //                 this.itemData.notify_users_ids.push(val[i].id);
                //                 this.chosenNotifyUsers.push(val[i]);
                //             }
                //         }
                //   }
                // }
            },
            unmergeChosenNotifyUsers(val){

                // if(val.id) {
                //     this.itemData.notify_users_ids.deleteVal(val.id);
                //     this.chosenNotifyUsers.deleteByVal('id',val.id);
                // }
            }
        },


    }
</script>
